:root {
  --text-white: #ffffff;
  --text-black: #000000;
  --btn-danger: #bf1e2e;
  --icon-gray-color: #787676;
  --light-gray-color: #979797;
  --text-black-color: #231f20;
  --border-color: #a19fa0;
  --primary-btn-color: #bf1e2e;
  --main-body-bg: #f2f2f2;
  --gray-secondary-color: #d4d4d4;
  --card-border: #f76420;
  --text-blue-600: #1f77d0;
}

:root.blue {
  --btn-danger: #bf1e2e;
  --icon-gray-color: #787676;
  --light-gray-color: #979797;
  --text-black-color: #231f20;
  --primary-btn-color: #1f77d0;
  --main-body-bg: #f2f2f2;
  --gray-secondary-color: #d4d4d4;
  --orange-border-color: #f76420;
  --text-blue-600: #1f77d0;
  --card-border: #1f77d0;
}

.cursor-pointer {
  cursor: pointer;
}
.text-primary-color {
  color: #bf1e2e;
}
.table-fixed {
  table-layout: fixed;
}
.profileButton {
  margin-right: 10px;
  /*margin-bottom: 5px;*/
  padding-left: 8px;
  padding-right: 8px;
  padding-bottom: 4.5px;
  padding-top: 4.5px;
  min-width: 130px;
  font-size: 1rem;
  border-radius: 0;
  font-weight: normal;
}
.headerButton.btn-primary {
  padding-block: 5px;
}
.profileButton.btn-primary {
  background-color: var(--primary-btn-color);
  border-color: var(--primary-btn-color);
  color: var(--text-white);
}
.profileButton.btn-primary:hover,
.profileButton.btn-primary:active,
.profileButton.btn-primary:focus {
  background-color: var(--primary-btn-color);
  box-shadow: none;
  border-color: var(--primary-btn-color);
}
.profileButton.btn-secondary {
  background-color: var(--text-black-color);
  border-color: var(--text-black-color);
  color: var(--text-white);
  border-color: var(--text-black-color);
}
.profileButton.btn-secondary:hover,
.profileButton.btn-secondary:active,
.profileButton.btn-secondary:focus {
  background-color: var(--text-black-color);
  box-shadow: none;
  border-color: var(--text-black-color);
}

.flex-basis-1 {
  flex-basis: 100% !important;
}
.max-w-100 {
  max-width: 100% !important;
}
.pl-3 {
  padding-left: 1rem !important;
}
imagetr:hover {
  border: 1px solid transparent;
  border-color: #ab2330;
  border-width: 1.1px;
  margin: -1px;
}
.pt-72 {
  padding-top: 72px;
}
.user-table {
  table-layout: fixed;
  width: 100%;
}

.ellipsis {
  max-width: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.fixed-table {
  table-layout: fixed;
}
.btn-outline-danger {
  border-color: red;
  color: red;
}
.btn:disabled {
  color: grey;
  border-color: grey;
}
/* .table > tbody > tr > td.tooltip {
  width: auto;
  overflow: visible;
  z-index: 11;
  background-color: #FFFFFF;
} */
.colFirstWrapper {
  padding-left: 6px !important ;
}
.table > tbody > tr > td.icon {
  width: 45px;
  text-overflow: inherit;
  overflow: visible;
  white-space: normal;
}
.table > thead > tr > th.centered {
  text-align: center !important;
}

.table > tbody > tr > td.centered {
  text-align: center !important;
}

.table > thead > tr > th.centered > div {
  justify-content: center !important;
}

.appleEventRow > td {
  color: gray !important;
}

.hipaaTextArea {
  width: 100%;
  height: 90%;
}

.hipaaDialogue {
  max-height: 100vh;
  max-width: 100vw;
}

.hipaaBody {
  max-height: 90%;
}

/* .modal {
  overflow-x: visible;
  overflow-y: visible;
} */
.employeeProfile label {
  font-size: 12px;
  margin-bottom: 5px;
  text-transform: uppercase;
  font-weight: 400;
  color: #9a9a9a;
  margin-bottom: 0px;
}
.overFlow-y-hidden {
  overflow-y: hidden;
}
.radioBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 20px;
}
.radioBox > input {
  margin-top: 0;
}
.rdioBox > label {
  margin-left: 5px;
}
.modalInput {
  border-radius: 5px;
  padding: 2px 10px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  min-height: 36px;
}
.crewPhoneInput > input {
  border-radius: 5px;
  padding: 2px 10px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  min-height: 36px;
}
.modalLineHeaders {
  padding-bottom: 0;
  margin-top: 10px;
  margin-bottom: 0;
}
.css-6j8wv5-Input {
  margin: 0 2px !important;
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}
.radioHead {
  display: flex;
}
.radioHead > * {
  flex-basis: 100%;
}

.roleSelector {
  margin: 0;
}

.buttonHeader {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  align-items: flex-start;
}
.row-gap-3 {
  row-gap: 1rem;
}
.profileButton {
  /* margin-right: 10px; */
  /*margin-bottom: 5px;*/
  padding-left: 8px;
  padding-right: 8px;
  padding-bottom: 4.5px;
  padding-top: 4.5px;
}
.preRegisterationViewWrapper {
  width: calc(100% - 40px) !important;
}

a.downloadTest {
  color: #3472f7;
  text-decoration: none;
}

.filterFields {
  margin-top: 10px;
}

.filterDrop {
  width: 145px;
  margin-right: 10px;
}

.searchInput {
  max-width: 500px;
  padding-left: 10px;
  border-top: none !important;
  border-right: none !important;
}

.searchFilterButton {
  width: 130px;
  margin-right: 10px;
}

.changeRoleSelector {
  width: 175px;
}

.createButtons {
  padding-top: 4.5px;
  padding-bottom: 4.5px;
  padding-right: 8px;
  padding-left: 8px;
}

.modalButtons {
  padding-top: 4.5px;
  padding-bottom: 4.5px;
  padding-right: 8px;
  padding-left: 8px;
}

.headerNav {
  display: flex;
  justify-content: space-between;
}

.toggleButtonGroup {
  width: 100px;
}

.checkboxRow {
  display: flex;
  flex-direction: column;
}

.dateRangeFilter {
  border-radius: 5px;
  padding: 2px 10px;
  color: #212529;
  border: 1px solid #ddd;
  max-width: calc(100% - 174px);
  width: 100%;

  height: 40px;
}

.releaseCheckbox {
  margin-right: 7px;
  margin-top: 10px;
  margin-bottom: 20px;
}

.checkboxLabel {
  margin-top: 10px;
}

.createLabModal {
  min-width: 90vw !important;
  min-height: 90vh !important;
}

.createLabBody {
  display: flex;
  flex-wrap: wrap;
}

.labAddressInput {
  display: flex;
  flex-direction: column;
  width: 97%;
}

.notesInput {
  width: 97%;
}

.labModalInput {
  padding: 2px 10px;
  background-color: #f9f9f9;
  width: 100%;
  border-radius: 5px;
  border: 1px solid #ddd;
}

.adminHomeTable td {
  border: none;
}

.adminHomeTable tr:hover {
  border: none;
}
.pdfView {
  flex: 1;
  width: 100%;
  height: 100%;
}

.pdfContainer {
  height: 170vh;
}

.footerText {
  font-size: 9px;
  padding-right: 10px;
}

.truncate {
  width: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.text-right {
  text-align: right;
}
.pr-0 {
  padding-right: 0;
}
.emailWidth {
  width: 20%;
}
.tdWidth10 {
  width: 10%;
}
.tdWidth12 {
  width: 12%;
}
.tdWidth14 {
  width: 14%;
}
.tdWidth16 {
  width: 16%;
}
.tdWidth18 {
  width: 18%;
}
.radioBox-wrapper span.radioButton {
  margin-left: 5px;
}
.vaccination-status-name {
  font-size: 12px !important;
  margin-bottom: 5px !important;
  text-transform: uppercase;
  color: #9a9a9a;
  font-weight: 400;
  min-height: 18px;
}
.vaccination-status {
  padding: 0.375rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: #212529;
  min-height: 38px;
  display: inline-block;
}
.form-check > label,
.form-check > input {
  color: #212529;
  padding-left: 0 !important;
}

.form-group-wrapper .roleSelector {
  width: 100%;
}
.form-group-wrapper.activeEmploye .roleSelector {
  width: 50%;
}
.form-group-wrapper.activeEmploye .siteSelector {
  width: 50%;
}
.form-group-wrapper.activeEmploye .siteSelector .css-14el2xx-placeholder {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}
.form-group-wrapper .fa-times-circle {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  cursor: pointer;
  color: rgb(168, 38, 50);
}

.modal-2xl {
  max-width: 80%;
}

.fa-star {
  color: #fcc603;
  font-size: 11px;
}
.fa-exclamation-circle {
  font-size: 15px;
  color: var(--amplify-primary-color);
}
.fa-check-circle {
  font-size: 15px;
  color: green;
}
.roleSelector .css-1s2u09g-control,
.siteSelector .css-1s2u09g-control {
  background-color: #f9f9f9;
  border-color: #ddd !important;
}
.roleSelector .css-1s2u09g-control:hover,
.siteSelector .css-1s2u09g-control:hover {
  border-color: #ddd !important;
}

/*.roleSelector .css-qc6sy-singleValue, .siteSelector .css-qc6sy-singleValue  {*/
/*  color: #888888;*/
/*}*/
/*.roleSelector .css-1pahdxg-control .css-qc6sy-singleValue {*/
/*  color: #000000 !important;*/
/*}*/

.createClientsWrapper {
  display: flex;
  flex-wrap: wrap;
  column-gap: 15px;
}
.createClientsWrapper .form-group-wrapper {
  flex: 0 0 calc(50% - 15px);
  max-width: calc(50% - 15px);
  width: 100%;
}
.form-group-wrapper .radioBox-wrapper {
  display: inline-block;
  min-width: 85px;
}
.checkbox-inline + .checkbox-inline,
.radio-inline + .radio-inline {
  margin-top: 0;
  margin-left: 10px;
}
.inputFileWrapper {
  width: 120px;
  height: 120px;
  border: 1px solid #ddd;
  border-radius: 50%;
}
.inputFileWrapper > input {
  opacity: 0;
  width: 100%;
  height: 100%;
}

.inputFileWrapperFull {
  width: 100%;
  height: auto;
  border: 1px solid #ddd;
  text-align: center;
  /* border-radius: 50%;  */
}
.inputFileWrapperFull > input {
  /* opacity: 0; */
  width: 100%;
  height: 100%;
}
.inputFileWrapperFull > img {
  width: 100%;
  height: 100%;
  /* max-height: 190px;
  min-height: 190px; */
  /* border-radius: 50%; */
}
.checkBoxWrapper {
  display: flex;
  align-items: center;
}
.checkBoxWrapper > span {
  /*flex: 0 0 60%;*/
  /*max-width: 60%;*/
  width: 100%;
}
.checkBoxWrapper input {
  flex: 0 0 auto;
}
.showsModal .checkBoxWrapper {
  width: 100%;
  /*column-gap: 15px;*/
}
.checkbox.checkBoxWrapper {
  justify-content: space-between;
}
.showsModal div .checkBoxWrapper span {
  width: 100%;
  font-size: 14px;
}
:root {
  --amplify-primary-color: rgb(168, 38, 50);
  --amplify-primary-tint: #31465f;
  --amplify-primary-shade: rgb(168, 38, 50);
}
.dashboard-page {
  display: flex;
  justify-content: flex-start;
  column-gap: 15px;
  row-gap: 15px;
  flex-wrap: wrap;
}
.dashboard-tab {
  flex: 0 0 calc(33.33% - 10px);
  max-width: calc(33.33% - 10px);
  width: 100%;
  border: 1px solid #fff;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
}
.dashboard-tab h4 {
  font-size: 24px;
  margin-top: 0;
  color: #565656;
  font-weight: bold;
  border-bottom: 1px solid #ddd;
  padding: 30px 15px 15px;
}
.pac-container {
  z-index: 1100 !important;
}
.dashboard-tab p {
  font-size: 24px;
  color: #a82632;
  margin: 0;
  padding: 0 15px 30px;
}
.labsModalWrapper {
  display: flex;
  column-gap: 15px;
}
.labsModalWrapper .form-group-wrapper {
  flex: 0 0 calc(50% - 7px);
  max-width: calc(50% - 7px);
}
.labsModalWrapper .form-group-wrapper table > tbody {
  display: block;
  max-height: 300px;
  overflow-y: scroll;
}
.labsModalWrapper .form-group-wrapper table thead tr,
.labsModalWrapper .form-group-wrapper table tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}
.result-filter-width {
  flex: 0 0 calc(100% - 174px);
  max-width: calc(100% - 174px);
  width: 100%;
}

.test-details-wrapper {
  border: 1px solid #E3E3E3;
}
.test-details-header {
  padding-bottom: 15px !important;
  border-bottom: 1px solid var(--border-color) !important;
}
.test-details-wrapper .card-text {
  font-size: 14px;
}
.test-details-wrapper .card-text > span {
  font-size: 14px;
  color: var(--icon-gray-color);
  min-width: 100px;
  display: inline-block;
}
.test-details-footer {
  border-top: 1px solid var(--light-gray-color) !important;
  padding-bottom: 0;
  margin-top: 5px;
}
.test-details-footer ul {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
  display: flex;
  justify-content: center;
}
.test-details-footer ul li {
  padding-block: 5px;
  padding-right: 5px;
  text-align: center;
  flex: 0 0 50%;
}
.test-details-footer ul li:not(:first-child) {
  border-left: 1px solid var(--light-gray-color);
  padding-left: 5px;
}
.test-details-wrapper .test-details-header label {
  color: var(--primary-btn-color);
  font-size: 16px;
  font-weight: bolder;
  margin-bottom: 0;
  text-transform: none;
}
.test-details-wrapper .imageWrapper {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 10px;
  min-height: 100px;
  max-height: 100px;
  height: 100px;
}
.test-details-wrapper .imageWrapper img {
  width: 100%;
  height: 70px;
  object-fit: contain;
}
.table-first-td:before {
  content: "";
  position: absolute;
  left: 0;
  height: 90%;
  width: 3px;
  background-color: #fcc603;
  top: 50%;
  border-radius: 5px 0 0 5px;
  transform: translateY(-50%);
}
.crew-minor-text {
  color: #ffffff;
  font-weight: 600;
}
.sidebarListing-badge {
  background-color: var(--primary-btn-color);
  color: white;
  margin-left: 5px;
  font-weight: bold;
  font-size: 13px;
  width: 20px;
  text-align: center;
  border-radius: 3px;
  box-shadow: 1px 2px #1a1919;
}
.sidebar-wrapper .logo {
  background-color: #f7f7f8;
  border-color: var(--primary-btn-color);
  border-width: 4px;
}

/*Dashboard page*/
.dashboard-wrapper {
  background-color: #f8f8f8;
}

.dashboard-wrapper .custom-card-body {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px !important;
}
.dashboard-wrapper .custom-card-body .detail img {
  width: 30px;
  height: 30px;
  margin-right: 20px;
}
.dashboard-wrapper .custom-card-body .detail .title {
  color: #464646;
  font-size: 16px;
  line-height: 1;
}
.dashboard-wrapper .custom-card-body .number {
  font-size: 32px;
  line-height: 1;
}
.dashboard-wrapper .total-test {
  background-color: #e7e4f9;
  border: 0;
}
.dashboard-wrapper .positive-test {
  background-color: #f1d8da;
  border: 0;
}
.dashboard-wrapper .negative-test {
  background-color: #dfedde;
  border: 0;
}
.dashboard-wrapper .total-number {
  color: #573bff;
}
.dashboard-wrapper .positive-number {
  color: #bf1e2e;
}
.dashboard-wrapper .negative-number {
  color: #08a003;
}
/*--search bar ---*/
.dashboard-wrapper .form {
  display: flex;
  flex-direction: row;
}
.dashboard-wrapper .search-field {
  height: 32px;
  padding: 2px 30px 2px 15px;
  border-radius: 100px;
  outline: none;
  border: 1px solid var(--light-gray-color);
  background-color: transparent;
  font-size: 13px;
  color: var(--light-gray-color);
  position: relative;
}
.dashboard-wrapper input::placeholder {
  font-size: 13px;
  color: var(--light-gray-color);
}

.dashboard-wrapper .search-icon {
  width: 20px;
  height: 20px;
  object-fit: cover;
  color: var(--light-gray-color);
  position: absolute;
  right: 10px;
  top: 8px;
}
.dashboard-wrapper .custom-title {
  font-size: 22px;
  color: #231f2d;
}
.dashboard-wrapper .search-card {
  border: none;
  background-color: #f3f3f3;
  padding: 0 10px;
  margin-top: 15px;
}
.dashboard-wrapper .search-card .card-body {
  padding-block: 10px;
}
/*--table--*/
.dashboard-wrapper .dashboard-tables-data {
  background-color: white;
  table-layout: fixed;
  width: 100%;
}
.dashboard-wrapper .dashboard-tables-data tbody {
  display: block;
  max-height: 175px;
  overflow-y: auto;
}
.dashboard-wrapper .dashboard-tables-data tbody tr {
  width: 100%;
  display: table;
}
.dashboard-wrapper .dashboard-tables-data tbody::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  background-color: #f1f1f1;
}

.dashboard-wrapper .dashboard-tables-data tbody::-webkit-scrollbar {
  width: 5px;
  background-color: #f1f1f1;
}

.dashboard-wrapper .dashboard-tables-data tbody::-webkit-scrollbar-thumb {
  background-color: #a8a8a8;
}
/**/
.dashboard-wrapper .table > tbody > tr > td {
  padding: 0 5px 5px 5px;
  width: 25%;
}
.dashboard-wrapper .table p {
  font-size: 12px;
  margin-bottom: 0;
}
.dashboard-wrapper .table span {
  display: inline-block;
  font-size: 12px;
}
.dashboard-wrapper .table .custom-title {
  color: var(--light-gray-color);
}
.dashboard-wrapper .table tr {
  border-bottom: 1px solid #979797;
  width: 100%;
  line-height: 1;
  display: table;
}
.dashboard-wrapper .border-bottom-transparent {
  border-bottom: 1px solid transparent !important;
  line-height: 1;
}
.dashboard-wrapper .border-bottom-transparent > td {
  padding-block: 5px !important;
}



/*crew member profile*/
.crew-member-profile-inner {
  background-color: white;
}
.crew-member-profile-inner .personal-info-wrapper {
  border-bottom: 1px solid #E3E3E3;
}
.crew-member-profile .imagePlaceholder {
  height: 40px !important;
}
.wrapper-profile label{
  color:#7a7878;
}
.wrapper-profile input::placeholder{
  color:#1f1b1c;
}
.wrapper-profile #Phone::placeholder {
  color: red;
}
.wrapper-profile .crew-image-wrapper {
  max-width: 120px;
  width: 100%;
  height: 120px;
  position: relative;
  margin: auto;
}
.wrapper-profile .crew-image-wrapper > input {
  position: absolute;
  bottom: -5px;
  width: 30px;
  right: 10px;
  opacity: 0;
  z-index: 1;
}
.profile-image img{
  width: 120px;
  height: 120px;
  object-fit: cover;
}
.profile-image .camera-icon{
  display: inline-flex;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #EBEBEB;
  position: absolute;
  bottom: -5px;
  right: 10px;
  align-items: center;
  justify-content: center;

}
.profile-image .camera-icon i{
  font-size: 14px;
  color: var(--light-gray-color);
}
.wrapper-profile .edit-icon {
  width: 18px;
}
.wrapper-profile .profile-title {
  font-size: 18px;
}
.wrapper-profile .profile-image > p {
  font-size: 16px;
}
.wrapper-profile .settings-profile span {
  color: var(--light-gray-color);
  font-size: 14px;
}
.wrapper-profile .settings-profile textarea {
  min-height: 100px;
  resize: none;
}
.crew-member-profile .additional-info .cards-heading {
  font-size: 14px;
  color: var(--icon-gray-color);
  margin-bottom: 5px;
}
.crew-member-profile .additional-info .cards-heading span {
  color: var(--primary-btn-color);
}
.crew-member-profile-inner .border-right-1 {
  border-right: 1px solid #E3E3E3;
}
.crew-member-profile-inner .border-left-1 {
  border-left: 1px solid #E3E3E3;
}
  /*switch toggle button*/
.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 18px;
  margin-bottom: 0 !important;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.switch .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s cubic-bezier(0,1,0.5,1);
  border-radius: 4px;
}

.switch .slider:before {
  position: absolute;
  content: "";
  height: 14px;
  width: 14px;
  left: 4px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s cubic-bezier(0,1,0.5,1);
  border-radius: 3px;
}

.switch input:checked + .slider {
  background-color: #08a003;
}
.switch input:checked + .slider:before {
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(20px);
}
.switch input:disabled {
  cursor: default;
}
.switch .slider.round.disabled {
  cursor: default;
  opacity: 0.5;
}
.switch .slider.round {
  border-radius: 34px;
}
.switch .slider.round:before {
  border-radius: 50%;
}


/*radio button style*/

.radio-button-wrapper input[type="radio"] {
  display: none;
}

.radio-button-wrapper label {
  cursor: pointer;
  position: relative;
  font-size: 1rem;
  padding-left: 25px;
}

.isPositiveResult > td {
  background-color: #fff0f0 !important;
  color: #ff2f2f !important;
}

.radio-button-wrapper label::before {
  content: "";
  position: absolute;
  width: 1rem;
  height: 1rem;
  background-color: var(--text-white);
  border: 1px solid var(--border-color);
  border-radius: 3px;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  transition: all 500ms ease;
}
.radio-button-wrapper input[type="radio"]:checked + label::before {
  border-color: var(--btn-danger);
  background-color: var(--btn-danger);
  transition: all 0.5s ease-in;
}
.radio-button-wrapper.colorBlack input[type="radio"]:checked + label::before {
  border-color: #000000;
  background-color: #000000;
}
.crew-member-profile .additional-info-radios label.status {
  color: var(--icon-gray-color);
  line-height: 1;
  margin-bottom: 5px;
}


.labelYesNo {
  text-decoration: underline;
  font-weight: bold;
  min-width: 35px;
  margin-right: 8px;
  display: inline-block;
}
.formWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 15px;
  padding-left: 15px;
}
.selectTestWrapper .form-group-wrapper {
  flex: 0 0 100%;
  max-width: 100%;
  width: 100%;
}
.imageWrapper {
  min-height: 150px;
}
.text-grey {
  color: grey !important;
}
.uploadFilesArea {
  margin-top: 36px;
}
.linkedText {
  color: var(--text-blue-600);
  cursor: pointer;
}
.tdWidth10 {
  width: 10%;
}
.tdWidth12 {
  width: 12%;
}
.showDisclaimerText {
  margin-left: 5px;
}
.showDisclaimerText span {
  color: #3472f7;
  cursor: pointer;
}
.selectorHeader {
  max-width: 300px;
}

.filterResetBtn {
  display: flex;
  justify-content: right;
  margin-bottom: -15px;
  margin-top: 10px;
}

.textUnderLine {
  text-decoration: underline;
}

.btn-warning {
  background-color: rgb(168, 38, 50) !important;
  border-color: rgb(168, 38, 50) !important;
}

.loginWrapper {
  max-width: 460px;
  margin: auto;
  background-color: #ffffff;
  padding: 35px 40px;
  border-radius: 15px;
  box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.15);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}
.loginWrapper h3 {
  font-weight: 600;
}
.loginWrapper .logo-width {
  width: 200px;
}
.loginWrapper input {
  font-size: 14px;
  height: 50px;
  /*color: var(--color);*/
  background-image: none;
  border: 1px solid rgb(196, 196, 196);
  padding: 1rem;
  border-radius: 3px;
}
.loginWrapper input::placeholder {
  color: rgb(130, 130, 130);
}
.loginWrapper label {
  font-size: 14px;
  font-weight: 400;
}
.loginWrapper input:active,
.loginWrapper input:focus {
  border-color: var(--primary-btn-color) !important;
}
.loginWrapper p {
  color: rgb(130, 130, 130);
  font-size: 12.96px;
  font-weight: 400;
}
.loginWrapper a {
  color: var(--primary-btn-color);
  font-weight: 500;
}
.loginWrapper a.resetPassword {
  text-decoration: none;
  font-weight: 400;
}
.loginWrapper a.resetPassword:hover {
  text-decoration: underline;
  color: rgb(49, 70, 95);
}
.loginWrapper .signin-btn {
  width: 100%;
  height: 48px;
  display: inline-block;
  margin-bottom: 0;
  font-size: 14px;
  font-family: var(--amplify-font-family);
  font-weight: 600;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  background-image: none;
  color: #ffffff;
  letter-spacing: 0.75px;
  text-transform: uppercase;
  background-color: var(--primary-btn-color);
  border-width: 1px;
  border: 1px solid var(--primary-btn-color);
  border-radius: 0;
  opacity: 1;
}
.loginWrapper .signin-btn:disabled {
  opacity: 0.8;
  background-color: var(--primary-btn-color) !important;
  border-color: var(--primary-btn-color);
}
.uploadFileHippa {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.fileNameHippa {
  border-radius: 3px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border: 1px solid #cccccc;
  padding: 0 10px;
}
.blinkText {
  position: absolute;
  top: 50px;
  right: 50px;
  color: red;
  font-weight: bold;
}
.pdfDownLink {
  text-decoration: none;
  color: #3472f7 !important;
}
.pdfDownLink:hover,
.pdfDownLink:active {
  text-decoration: none !important;
  color: #3472f7 !important;
}
.marginTop0 {
  margin-top: 0 !important;
}
.results-sent-title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.registerFormWrapper {
  min-height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px 15px;
}
.registerWrapper {
  max-width: 500px;
  background-color: #ffffff;
  padding: 35px 30px;
  border-radius: 15px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  width: 100%;
}
.registerWrapper h3 {
  font-weight: 600;
}
.registerWrapper .logo-width {
  width: 200px;
}
.registerWrapper input.form-control {
  font-size: 14px;
  color: var(--color);
  background-image: none;
  border: 1px solid rgb(196, 196, 196);
  padding-inline: 1rem;
  height: 40px;
  border-radius: 3px;
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
}
.registerWrapper input::placeholder {
  color: rgb(130, 130, 130);
}
.registerWrapper label {
  font-size: 14px;
  font-weight: 400;
}
.registerWrapper input:active,
.registerWrapper input:focus {
  border-color: rgb(168, 38, 50) !important;
}
.registerWrapper p {
  color: rgb(130, 130, 130);
  font-size: 12.96px;
  font-weight: 400;
}
.registerWrapper a {
  color: rgb(168, 38, 50);
  font-weight: 500;
}

.registerWrapper .signup-btn {
  width: 10 0%;
  height: 38px;
  display: inline-block;
  margin-bottom: 0;
  font-size: 14px;
  font-family: var(--amplify-font-family);
  font-weight: 600;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  background-image: none;
  color: #ffffff;
  letter-spacing: 0.75px;
  text-transform: uppercase;
  background-color: #a82632;
  border-width: 1px;
  border: 1px solid #a82632;
  border-radius: 0;
  opacity: 1;
}
.registerWrapper .signup-btn:disabled {
  opacity: 0.8;
  background-color: #a82632 !important;
  border-color: #a82632;
}
.codeScannerWrapper {
  min-height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.registered-successful {
  font-family: sans-serif;
  text-align: center;
  width: 450px;
}
.registered-successful h2 {
  font-weight: 500;
  margin: 5px;
  font-size: 16px;
}
.card-name {
  padding-bottom: 60px;
}
.registered-inner {
  padding: 30px 60px;
}
.error-message {
  text-align: center;
  color: red;
}
.form-group-wrapper .stateField {
  text-transform: uppercase;
}
.form-group-wrapper .stateField::placeholder {
  text-transform: capitalize;
}

.edit_style {
  border: 1px solid #b6b4b4;
  border-radius: 5px;
  padding: 2px 10px;
  color: gray !important;
  min-height: 40px;
}
.edit_style.selected {
  color: var(--color) !important;
}

.dobInput:read-only {
  background-color: #fff;
}
.pdfWrapper {
  height: 170vh;
  flex: 0 0 1;
  width: 100%;
}
.card .card-body {
  padding: 15px 30px;
}
.card .card-body .table {
  margin-bottom: 0;
}
.qrWrapper {
  /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */
  position: relative;
  border-radius: 10px;
  border: 1px solid lightgray;
}
.userIcon {
  position: absolute;
  top: -45px;
  left: 50%;
  transform: translateX(-50%);
  width: 90px;
  height: 90px;
  border-radius: 50%;
}
.qrCode {
  margin-top: 30px;
}
.form-group-wrapper .PhoneInput {
  width: calc(100% - 100px);
}
.form-group-wrapper .PhoneInput input {
  height: 40px;
}
.form-group-wrapper .PhoneInputInput {
  height: 40px;
  border-radius: 3px;
  border: 1px solid rgb(196, 196, 196);
}
.form-group-wrapper .PhoneInputInput:active,
.form-group-wrapper .PhoneInputInput:focus {
  outline: none;
}
.form-group-wrapper input[type="file"] {
  height: 40px;
  padding-top: 5px;
  padding-left: 5px;
  border-radius: 3px;
}
.form-group-wrapper .vaccinationField {
  border-radius: 3px;
  position: relative;
  border: 0;
}
.form-group-wrapper .vaccinationField input[type="file"] {
  position: relative;
  opacity: 0;
  z-index: 1;
}
.form-group-wrapper .vaccinationField:after {
  content: "Upload Image";
  position: absolute;
  font-size: 14px;
  left: 5px;
  margin-top: 5px;
  background-color: var(--primary-btn-color);
  width: 100px;
  height: 30px;
  border-radius: 3px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}
.profileEditImage {
  position: absolute;
  bottom: 10px;
  right: -5px;
  background-color: #f5f5f5;
  width: 30px;
  height: 30px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
}
.positionRelative {
  position: relative;
}
.createShowWrapper {
  display: flex;
  justify-content: space-between;
}
.createShowInner {
  flex: 0 0 70%;
  max-width: 70%;
  width: 100%;
  padding-top: 20px;
}
.createSettingsWrapper {
  flex: 0 0 30%;
  max-width: 30%;
  width: 100%;
  padding: 20px 20px 0 20px;
  border-left: 1px solid lightgray;
}
.fontFourteen {
  font-size: 14px;
}
.messages-area-show {
  padding-right: 15px;
}
.messages-area-show .form-group-wrapper > label {
  flex: 0 0 15%;
  max-width: 15%;
}
.client-dashboard-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  row-gap: 20px;
}
.card-dashboard-wrapper {
  padding: 3rem 1.5rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid #e7eaed;
  border-radius: 0.25rem;
  flex: 0 0 calc(100% / 4 - 15px);
  max-width: calc(100% / 4 - 15px);
  column-gap: 15px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
.card-dashboard-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.content-wrapper {
  flex: 0 0 70%;
  max-width: 70%;
}
.card-dashboard-inner .image-wrapper {
  flex: 0 0 30%;
  max-width: 30%;
  text-align: right;
}
.content-wrapper h6 {
  margin: 0;
  padding-bottom: 1rem;
  text-transform: uppercase;
  color: #7f7d7d;
  font-size: 18px;
  font-weight: 500;
}
.content-wrapper h4 {
  margin: 0;
  font-size: 20px;
  font-weight: 600;
}
.card-dashboard-inner .image-wrapper img {
  width: 70px;
  height: 70px;
}
.qr-code-download {
  width: 65px;
}
.qr-code-download div > i {
  font-size: 16px;
  color: green;
  display: inline-block;
  cursor: pointer;
}
.copy-link-text {
  position: absolute;
  top: 3px;
  left: 40%;
  font-size: 12px;
  color: green;
}
.codeScannerPhoneInput {
  min-height: auto;
}
/*custom-radio-btn-*/

.custom-radio-btn-wrapper .radio-custom {
  opacity: 0;
  position: absolute;
}

.custom-radio-btn-wrapper .radio-custom,
.radio-custom-label {
  vertical-align: middle;
  margin: 5px;
  cursor: pointer;
  width: 300px;
  border: 1px solid lightgray;
  border-radius: 5px;
  padding: 20px;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.custom-radio-btn-wrapper .radio-custom-label {
  position: relative;
}

.custom-radio-btn-wrapper .radio-custom + .radio-custom-label:before {
  content: "";
  background: #fff;
  border: 2px solid #ddd;
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 20px;
  padding: 2px;
  /*margin-right: 10px;*/
  text-align: center;
  line-height: 15px;
  position: absolute;
  right: 15px;
}

.custom-radio-btn-wrapper .radio-custom + .radio-custom-label:before {
  border-radius: 50%;
}

.custom-radio-btn-wrapper .radio-custom:checked + .radio-custom-label:before {
  /*background: #ccc;*/
  content: "✓";
}

.datefilter-label {
  padding-left: 25px;
  font-weight: bold;
}

.labDownloadTest:disabled a {
  color: grey;
}
.filterDateInput {
  flex-basis: 100% !important;
  max-width: 100% !important;
  border: 0 !important;
  background: transparent !important;
}
.personalizationCheckbox input {
  width: 20px;
  height: 20px;
}
.personalizationCheckbox label {
  font-size: 18px;
  margin-left: 10px;
  display: inline-block;
  position: relative;
  top: -2px;
}

.preRegistrationOrderDetails {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  flex-direction: row;
}
.OrderDetailFont {
  font-weight: bold;
  font-size: 18px;
}
.weightBold {
  font-weight: bold;
}

.preRegistrationStat {
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  border: 1px solid;
  padding: 10px 20px;
  border-radius: 5px;
  border-style: groove;
  margin: 20px 20px 10px 0;
}
.employeeImageView > img {
  width: 418px !important;
  height: 150px !important;
}
.imageView {
  width: 120px;
  height: 120px;
}
.column-gap-15 {
  column-gap: 10px;
}
.row-gap-15 {
  row-gap: 10px;
}
.scanner-wrapper {
  width: 120px;
  border: 1px solid #222;
  text-align: center;
  padding: 0px 10px 10px 10px;
}
.scanner-wrapper p {
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  text-overflow: ellipses;
}
.flex-wrap {
  flex-wrap: wrap;
}
.column-gap-10 {
  column-gap: 10px;
}
.custom-dropdown button {
  border: 0;
}
.custom-dropdown button:after {
  opacity: 0;
}
.custom-dropdown .dropdown-item:first-child:hover {
  border-radius: 10px 10px 0 0;
}
.custom-dropdown .dropdown-item:last-child:hover {
  border-radius: 0 0 10px 10px;
}
.custom-dropdown button img {
  width: 20px;
}
.second-column-pt {
  padding-top: 45px !important;
}

.modalHeight {
  min-height: 20vh;
}
.externalTestModal .modal-content {
  border: 1px solid black !important;
}
.testEdit-Modal-Header {
  background-color: var(--primary-btn-color) !important;
}
.edit-Button > svg {
  fill: #000000 !important;
}
/*crew-member-profile*/
.crew-member-profile .member-profile-card {
  border: 2px solid var(--card-border);
}
.crew-member-profile .member-profile-card .card-header {
  background-color: var(--primary-btn-color);
  border: 2px solid transparent;
  padding: 15px;
}
.crew-member-profile .member-profile-card .card-header .card-title {
  color: var(--text-white) !important;
  font-size: 18px;
}
.crew-member-profile .member-profile-card .card-header:first-child {
  border-radius: 0;
}
.crew-member-profile .profile-image-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 20px;
}
.crew-member-profile .profile-image-wrapper img {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  object-fit: cover;
}
.crew-member-profile .profile-image-wrapper p {
  font-size: 14px;
  color: var(--light-gray-color);
}
.crew-member-profile .profile-image-wrapper small {
  color: var(--primary-btn-color);
}
.inputFileWrapper > img {
  width: 100% !important;
  height: 100% !important;
  border-radius: 50%;
}

.testAdmin.createClientsWrapper .form-group-wrapper {
  flex: 0 0 100%;
  max-width: 100%;
  width: 100%;
  line-height: 1;
  display: block;
}

.testAdmin.createClientsWrapper .first-last-name-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  column-gap: 30px;
}
.testAdmin.createClientsWrapper .first-last-name-wrapper .form-group-wrapper {
  flex: 0 0 calc(50% - 15px);
  max-width: calc(50% - 15px);
  display: block;
}

.testAdmin.createClientsWrapper .crew-profile-info {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  width: 100%;
  column-gap: 16px;
}
.testAdmin.createClientsWrapper .crew-profile-row2 {
  display: grid;
  width: 100%;
  column-gap: 16px;
  grid-template-columns: 1.55fr 1.55fr 1fr;
}

.testAdmin.createClientsWrapper .form-group-wrapper > label {
  color: var(--icon-gray-color);
  line-height: 1;
  padding-left: 10px;
  margin-bottom: 5px;
}
.testAdmin.createClientsWrapper .form-group-wrapper > label,
.testAdmin.createClientsWrapper .form-group-wrapper > input {
  flex: 0 0 100%;
  max-width: 100%;
  width: 100%;
  font-size: 14px;
}
.crew-member-profile .testAdmin.createClientsWrapper .form-group-wrapper > input {
  height: 40px;
}
.testAdmin.createClientsWrapper .form-group-wrapper > input.height-32 {
  height: 32px;
}
.form-group-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 15px;
  margin-bottom: 15px;
}
.form-group-wrapper > label {
  flex: 0 0 30%;
  max-width: 30%;
  width: 100%;
  margin-top: 0;
  font-size: 14px;
}
.form-group-wrapper > input {
  flex: 0 0 calc(100% - 30% - 15px);
  max-width: calc(100% - 30% - 15px);
  width: calc(100% - 30% - 15px);
}
.form-group-wrapper.dateWrapper > input {
  flex: 0 0 calc(100% - 30% - 15px);
  max-width: calc(100% - 30% - 15px);
  width: calc(100% - 30% - 15px);
  margin-bottom: 0 !important;
}
.form-group-wrapper .textAreaWrapper {
  width: 100%;
  height: 80px;
  max-height: 80px;
  overflow-y: auto;
  resize: none;
  border-radius: 5px;
  padding: 5px 10px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  font-size: 15px;
  line-height: 1.2;
}
.form-group-wrapper .textAreaWrapper:hover,
.form-group-wrapper .textAreaWrapper:active,
.form-group-wrapper .textAreaWrapper:focus {
  outline: none;
}

.vaccination-rows-data {
  box-shadow: rgb(60 64 67 / 30%) 0px 1px 2px 0px, rgb(60 64 67 / 15%) 0px 2px 6px 2px;
  padding: 10px;
  text-align: center;
  border-radius: 10px;
  margin-bottom: 20px;
}
.vc-form-group > div {
  border-right: 1px solid grey;
}
.vc-form-group:last-child > div {
  border-right: 0;
}
.vc-form-group input {
  text-align: center;
}
.vc-form-group .vc-right-image {
  border-right: 1px solid grey !important;
}
.underlinedText {
  text-decoration: underline;
  color: var(--primary-btn-color) !important;
}
.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  right: 50%;
  color: var(--primary-btn-color);
}
.horizontalLoader {
  position: relative;
  left: 50%;
  right: 50%;
  color: var(--primary-btn-color);
}
.emp-updated-text {
  color: var(--primary-btn-color);
  font-size: 18px;
}
.safecamp-admin-wrapper > label {
  flex: 0 0 20%;
  max-width: 20%;
  width: 100%;
  margin-top: 0;
  font-size: 14px;
}
.safecamp-admin-wrapper > input {
  flex-basis: calc(100% - 20% - 15px);
  max-width: calc(100% - 20% - 15px);
  width: calc(100% - 20% - 15px);
}
.safecamp-admin-wrapper .PhoneInput {
  width: calc(100% - 75px);
}
/**/
/*breakPoints*/
@media only screen and (min-width: 1366px) {
  .desktop-noScroll .table-responsive {
    overflow-x: hidden;
  }
}

@media only screen and (min-width: 1600px) {
  .filterDrop {
    width: 150px;
  }
  .dashboard-wrapper .dashboard-tables-data tbody {
    max-height: 12vw;
  }
}

@media only screen and (min-width: 1920px) {
  .dashboard-wrapper .dashboard-tables-data tbody {
    max-height: 16vw;
  }
}

@media (max-width: 991px) {
  .changeRoleSelector {
    width: 100%;
  }
}
@media only screen and (max-width: 992px) {
  .createClientsWrapper .form-group-wrapper {
    flex: calc(100% - 0px);
    max-width: calc(100% - 0px);
  }
  .showsModal div .checkBoxWrapper span {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .labsModalWrapper {
    flex-direction: column;
  }
  .labsModalWrapper .form-group-wrapper {
    flex: calc(100% - 0px);
    max-width: calc(100% - 0px);
  }
  .uploadFilesArea {
    margin-top: 15px;
  }
}
@media only screen and (max-width: 1199px) {
  .createShowWrapper {
    display: block;
  }
  .createShowInner,
  .createSettingsWrapper {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .messages-area-show {
    padding-right: 0;
  }
  .messages-area-show .form-group-wrapper > label {
    flex: 0 0 30%;
    max-width: 30%;
  }
  .createSettingsWrapper {
    border-left: 0;
    padding-left: 0;
    padding-right: 0;
  }
}

@media only screen and (max-width: 1366px) {
  .card-dashboard-wrapper {
    flex: 0 0 calc(100% / 3 - 15px);
    max-width: calc(100% / 3 - 15px);
  }
}
@media only screen and (max-width: 1199px) {
  .preRegistrationOrderDetails {
    justify-content: space-between;
  }
  .preRegistrationStat:nth-child(2n) {
    margin-right: 0;
  }
  .preRegistrationStat {
    flex: 0 0 calc(100% / 2 - 40px);
  }
}
@media only screen and (max-width: 1024px) {
  .card-dashboard-wrapper {
    flex: 0 0 calc(100% / 2 - 15px);
    max-width: calc(100% / 2 - 15px);
  }
}
@media only screen and (max-width: 992px) {
  .vc-form-group > div {
    border-right: 0;
    border-bottom: 1px solid grey;
  }
  .vc-form-group:last-child > div {
    border-right: 0;
    border-bottom: 0;
  }
  .vc-form-group input {
    text-align: center;
  }
  .vc-form-group .vc-right-image {
    border-right: 0 !important;
    border-bottom: 1px solid grey !important;
  }
  .preRegistrationStat {
    flex: 0 0 calc(100% / 2 - 40px);
  }
  .testAdmin.createClientsWrapper .crew-profile-info,
  .testAdmin.createClientsWrapper .crew-profile-row2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}

@media only screen and (max-width: 576px) {
  .card-dashboard-wrapper {
    flex: 0 0 calc(100% / 1 - 0px);
    max-width: calc(100% / 1 - 0px);
  }
  .preRegisterationViewWrapper {
    margin-left: 0;
    width: calc(100% - 0px) !important;
  }
  .preRegistrationStat {
    margin-left: 0;
    margin-right: 0;
    flex: 0 0 100%;
  }
  .testAdmin.createClientsWrapper .crew-profile-info,
  .testAdmin.createClientsWrapper .crew-profile-row2 {
    display: block;
  }
}

.qrCodeWrapper {
  border-radius: 10px;
  border: 1px solid lightgray;
  padding-top: 20px;
  padding-bottom: 20px;
}
.qrSubdiv {
  border: none;
}
.printQrCode {
  display: none;
}
@media print {
  @page {
    size: 4in 6in;
  }
  .registered-successful h3,
  .registered-successful p,
  .qrCodeWrapper,
  .qrCodeWrapper .qrSubdiv,
  .downloadQrBtn {
    display: none;
  }
  .qrCodeWrapper.printQrCode {
    max-width: 100px;
    margin-inline: auto;
    padding-block: 5px;
    display: block;
  }
  .printQrCode #react-qrcode-logo {
    width: 75px !important;
    height: 75px !important;
  }
  .printQrCode .qrSubdiv {
    font-size: 10px !important;
    display: block;
  }
}
